export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'admin-dashboard',
    // tag: '2',
    // tagVariant: 'light-warning',
    // acl: {
    action: 'read',
    resource: 'Public'
    // },
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    route: 'new-admin-users-list'
  },
  {
    title: 'Services',
    icon: 'ToolIcon',
    route: 'admin-services-list'

  },
  {
    title: 'Vehicles',
    icon: 'TruckIcon',
    route: 'admin-vehicles-list'

  },
  {
    title: 'Appointments',
    icon: 'CalendarIcon',
    route: 'apps-calendar'

  },
  {
    title: 'Orders',
    icon: 'ShoppingCartIcon',
    route: 'admin-orders-list'

  }

]
