<template>
  <footer
    class="bg-white py-2"
  >
    <div
      class="container p-10"
    >
      <div class="row justify-content-between">
        <div class="col-12 order-3 my-1 my-lg-0 order-lg-1 col-lg-auto d-flex align-items-center">
          <span class="text-secondary text-small">Copyrights © {{ new Date().getFullYear() }} All Rights Reserved by Washir Inc.</span>
        </div>
        <div class="col-12  order-1 order-lg-2 my-1 my-lg-0 col-lg-auto">
          <b-nav>
            <b-nav-item
              class="footer-nav-item"
              link-classes="text-secondary  mb-1 mb-lg-0"
              :to="{ name: 'about-us' }"
            >
              About
            </b-nav-item>
            <b-nav-item
              class="footer-nav-item"
              link-classes="text-secondary  mb-1 mb-lg-0"
              :to="{ name: 'faq' }"
            >
              FAQ
            </b-nav-item>
            <b-nav-item
              class="footer-nav-item"
              link-classes="text-secondary  mb-1 mb-lg-0"
              :to="{ name: 'contact' }"
            >
              Contact
            </b-nav-item>
            <b-nav-item
              class="footer-nav-item"
              link-classes="text-secondary  mb-1 mb-lg-0"
              :to="{ name: 'terms' }"
            >
              Terms of Use
            </b-nav-item>
            <b-nav-item
              class="footer-nav-item"
              link-classes="text-secondary  mb-1 mb-lg-0"
              :to="{ name: 'privacy' }"
            >
              Privacy Policy
            </b-nav-item>
          </b-nav>
        </div>
        <div class="col-12 order-2 my-1 my-lg-0 order-lg-3 col-lg-auto">
          <b-nav>
            <b-nav-item link-classes="text-secondary">
              <feather-icon
                icon="FacebookIcon"
                size="18"
              />
            </b-nav-item>
            <b-nav-item link-classes="text-secondary">
              <feather-icon
                icon="LinkedinIcon"
                size="18"
              />
            </b-nav-item>
            <b-nav-item link-classes="text-secondary">
              <feather-icon
                icon="TwitterIcon"
                size="18"
              />
            </b-nav-item>
            <b-nav-item link-classes="text-secondary">
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-nav-item>
          </b-nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

import { BNav, BNavItem } from 'bootstrap-vue'
export default {
  components: { BNav, BNavItem }
}
</script>
<style lang="scss" scoped >
.breadcrumb-item a{
  color: #fff;
}

.footer-nav-item {
  width: 50%;
  @media (min-width: 992px) {
    width: auto;
  }
}
</style>
