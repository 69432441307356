















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar
} from 'bootstrap-vue'
import { userAbility } from '@/libs/acl/config'
import { Action, Getter } from '@/store/decorators'
import AuthModule from '@/store/modules/authModule'
import { User } from '@/models/User'

@Component({
  name: 'UserDropdown',
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar
  }
})
export default class UserDropdown extends Vue {
  @Action(AuthModule, 'logout') private logoutUser!: () => Promise<void>
  @Getter(AuthModule, 'activeUser') private activeUser!: User

  private logout () {
    this.logoutUser()
    // Reset ability
    this.$ability.update(userAbility.public)
    // Redirect to login page
    this.$router.push({ name: 'home-page' }).catch(() => {})
  }
}
