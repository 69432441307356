

































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'AppLogo',
  components: {}

})

export default class AppLogo extends Vue {
  @Prop({ default: 'default' }) size: string
}
